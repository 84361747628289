import React, { Component, Suspense } from "react";
import { withRouter } from "react-router-dom";

const Navigation = React.lazy(() => import("./Navigation"));
const TopBar = React.lazy(() => import("./Topbar"));
const AdminTopBar = React.lazy(() => import("./AdminTopBar"));

class Layout extends Component {
  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="double-bounce1"></div>
            <div className="double-bounce2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Suspense fallback={this.Loader()}>
          <div id="my-page">
            {this.props.location.pathname === "/mod/uploadcsv" ||
            this.props.location.pathname === "/mod/reportez" ||
            this.props.location.pathname === "/mod/csvupload" ||
            this.props.location.pathname === "/mod/comparador" ||
            this.props.location.pathname === "/mod/bitacora" ||
            this.props.location.pathname === "/mod/reportec" ? (
              <Navigation />
            ) : this.props.location.pathname === "/admin/sitios" ||
              this.props.location.pathname === "/admin/genlla" ||
              this.props.location.pathname === "/admin/usuarios" ? (
              <AdminTopBar />
            ) : (
              <TopBar />
            )}
            <div className="main-container bg-img">{this.props.children}</div>
          </div>
        </Suspense>
      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
